import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'
import Menu from '../../molecules/menu/menu'

const shouldForwardProp = (prop) => !['message', 'position'].includes(prop)

const Header = styled('header', { shouldForwardProp })`
	${({ message, position }) => (position === 'top' || !message ? `` : '')}
`

const GridContainer = styled(MuiGrid)`
	padding: ${({ theme }) => theme.spacing(0, 2)};
	position: relative;
	z-index: 1;
	.mini-trigger {
		padding: 0;
	}
`

const LogoWrapper = styled('div')`
	max-width: 151px;
	width: 100%;
	svg {
		width: 100%;
	}
`

const MenuStyled = styled(Menu)`
	display: block;
	padding-left: 148px;
`

export { GridContainer, Header, LogoWrapper, MenuStyled }
