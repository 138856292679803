import { Root as StyledRoot } from './sticky-headroom.styled'
import { LogoRoot } from '../../organisms/header/logo.styled'
import { styled } from '@mui/system'
import { UNPINNED, PINNED } from './constants'
import { LinkRoot as MenuLink } from '../../molecules/menu/menu-first-level.styled'
import { ButtonIcon as AccountIcon } from '../../organisms/header/header.styled'
import { ButtonStyled as WishlistIcon } from '../../molecules/mini-wish-list/mini-wish-list.styled'
import { ButtonStyled as CartIcon } from '../../molecules/minicart/minicart.styled'
import { SvgIcon as SearchIcon } from '../../molecules/search/search.styled'
import { MuiStackStyled as Divider } from '../../organisms/header/header-compact.styled'
import { HamburgerButton } from '../../organisms/sidebar-navigation/sidebar-navigation.styled'

export const Root = styled(StyledRoot)`
	${({
		mode,
		overlap,
		look,
		theme: {
			palette: {
				background: { header },
				white: { main: white },
				primary: { main: primary },
			},
		},
	}) =>
		mode === PINNED || mode === UNPINNED
			? `
            background:unset;
         transition: all 0.2s ease-out, transform 0.2s ease-out;
            header{
                position:relative;
                &::after {
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background: rgba(255, 255, 255, 0.70);
                backdrop-filter: blur(50px);
                    content: '';
                }
            }
            ${LogoRoot}{
                svg{
                    fill: ${primary};
                }
            }
            ${MenuLink}{
                color: ${primary};
            }
            ${SearchIcon}{
                color: ${primary};
            }
            ${AccountIcon}{
                .MuiSvgIcon-root{
                    color: ${primary};
                }
            }
            ${WishlistIcon}{
                .MuiBadge-root >.MuiSvgIcon-root {
                    color: ${primary};
                }
            }
            ${CartIcon}{
                .MuiBadge-root >.MuiSvgIcon-root {
                    color: ${primary};
                }
            }
            ${HamburgerButton}{
                .MuiBadge-root >.MuiSvgIcon-root {
                    color: ${primary};
                }
            }
            ${Divider}{
                > span {
                    background-color: ${primary};
                }
            }
         
   `
			: overlap
			? `
            background:unset;
            header{
                position:relative;
                &::after {
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background: transparent;
                    backdrop-filter: blur(0px);
                    content: '';
                }  
            }
        ${LogoRoot}{
            svg{
                transition: all 0.2s ease-out;
                fill: ${look == 'dark' ? primary : white};
            }
        }
        ${MenuLink}{
            transition: color 0.2s ease-out;
            .MuiLink-root{
                transition: none;
            }
            color: ${look == 'dark' ? primary : white};
        }
        ${SearchIcon}{
            transition: color 0.2s ease-out;
            color: ${look == 'dark' ? primary : white};
        }
        ${AccountIcon}{
            .MuiSvgIcon-root{
                transition: color 0.2s ease-out;
                color: ${look == 'dark' ? primary : white};
            }
        }
        ${WishlistIcon}{
            .MuiBadge-root {
            >span{
                transition: all 0.2s ease-out;
                color: ${look == 'dark' ? primary : white};
                border-color:${look == 'dark' ? primary : white};
            }
            >.MuiSvgIcon-root {
                transition: color 0.2s ease-out;
                color: ${look == 'dark' ? primary : white};
            }
        }
        }
        ${CartIcon}{
            .MuiBadge-root{
                >span{
                    transition: all 0.2s ease-out;
                    color: ${look == 'dark' ? primary : white};
                    border-color:${look == 'dark' ? primary : white};
                }
                >.MuiSvgIcon-root {
                    transition: color 0.2s ease-out;
                    color: ${look == 'dark' ? primary : white};
                }
            }
        }
        ${HamburgerButton}{
            >.MuiSvgIcon-root {
                color: ${look == 'dark' ? primary : white};
            }
            .MuiBadge-root >.MuiSvgIcon-root {
                transition: color 0.2s ease-out;
                color: ${look == 'dark' ? primary : white};
            }
        }
        ${Divider}{
            > span {
                transition: background-color 0.2s ease-out;
                background-color: ${look == 'dark' ? primary : white};
            }
        }
        `
			: `background-color: ${header};
        
        ${MenuLink}{
            color: ${look == 'dark' ? white : primary};
        }
        `};

	:hover {
		${({
			overlap,
			theme: {
				palette: {
					primary: { main: primary },
				},
			},
		}) =>
			overlap
				? `
                background:unset;
            color: ${primary};
            header{
                position:relative;
                &::after {
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background: rgba(255, 255, 255, 0.7);
                    backdrop-filter: blur(50px);
                    content: '';
                }    
            }
            ${LogoRoot}{
                    svg{
                        fill: ${primary} !important;
                    }
                }
            ${MenuLink}{
                color: ${primary};
            }
            ${SearchIcon}{
                color: ${primary};
            }
            ${AccountIcon}{
                .MuiSvgIcon-root{
                    color: ${primary};
                }
            }
            ${WishlistIcon}{
                .MuiBadge-root{
                    >span{
                        color: ${primary};
                        border-color:${primary};
                    }
                     >.MuiSvgIcon-root {
                        color: ${primary};
                    }
                }
            }
            ${CartIcon}{
                .MuiBadge-root {
                    >span{
                        transition: all 0.2s ease-out;
                        color: ${primary};
                        border-color:${primary};
                    }
                    >.MuiSvgIcon-root {
                        color: ${primary};
                    }
                }
            }
            ${HamburgerButton}{
                >.MuiSvgIcon-root {
                    color: ${primary};
                }
                .MuiBadge-root >.MuiSvgIcon-root {
                    color: ${primary};
                }
            }
            ${Divider}{
                > span {
                    background-color: ${primary};
                }
            }
                `
				: ''}
	}
`
